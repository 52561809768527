import React from "react";
import Layout from "../components/layout";
import Sidebar from "../components/organisms/Sidebar/sidebar.components";
import Nav from "../components/organisms/Nav/nav.components";
import SEO from "../components/seo";
import { graphql, PageProps } from "gatsby";
import {
  AllBlogLink,
  Avatar,
  ContentContainer,
  ContentTitle,
  ContentText,
  ContentProperties,
  CoverImage,
  DivNext,
  DivPrev,
  Dot,
  Item,
  Pagination,
  Span,
  TextPrev,
  TextNext,
  LinkNext,
  LinkPrev,
  CoverImgWrapper,
  Media,
  MediaLeft,
  AuhtorImg,
  AuthorInfo,
  AuthorName,
  AuthorDescription,
  Text,
  Divider,
} from "./post.styles";
import {
  Content,
  FacebookIcon,
  Footer,
  FooterLink,
  FooterWrapper,
  Icons,
  InstagramIcon,
  Link,
  LinkedinIcon,
} from "../components/organisms/Container/container.styles";

const Article: React.FC<PageProps> = ({ pageContext }) => {
  const [open, setOpen] = React.useState(false);

  const toggle = () => {
    setOpen(!open);
  };
  const { post, next, previous } = pageContext;
  const prevArticle = previous
    ? {
        url: `/blog/${previous.frontmatter.url}`,
        title: previous.frontmatter.title,
      }
    : null;

  const nextArticle = next
    ? {
        url: `/blog/${next.frontmatter.url}`,
        title: next.frontmatter.title,
      }
    : null;
  return (
    <Layout>
      <SEO title={post.frontmatter.title} description={post.frontmatter.desc} />
      <Sidebar open={open} toggle={toggle} />
      <Nav toggle={toggle} open={false} />
      <ContentContainer>
        <ContentProperties>
          {post.frontmatter.tags.map((tag, index) => (
            <AllBlogLink key={index}>{tag.name}</AllBlogLink>
          ))}
          <Dot />
          <Item>{post.timeToRead} min</Item>
        </ContentProperties>
        <ContentTitle>{post.frontmatter.title}</ContentTitle>
        <ContentProperties>
          <Item>
            {post.frontmatter.author_avatar.map((avi, index) => (
              <span key={index}>
                <Avatar src={avi.external.url} />
              </span>
            ))}
            {post.frontmatter.author_name} <Dot>•</Dot>
            {post.frontmatter.publish_date.start}
          </Item>
        </ContentProperties>
        {post.frontmatter.cover_image.map((img, index) => (
          <CoverImgWrapper key={index}>
            <CoverImage src={img.external.url} width={800} />
          </CoverImgWrapper>
        ))}
        <ContentText dangerouslySetInnerHTML={{ __html: post.html }} />
        <Divider />
        {post.frontmatter.name_author ? (
          <Media>
            <MediaLeft>
              {post.frontmatter.img_author.map((avi, index) => (
                <AuhtorImg key={index} src={avi.external.url} />
              ))}
            </MediaLeft>
            <AuthorInfo>
              {post.frontmatter.name_author ? (
                <Text>ESCRITO POR</Text>
              ) : (
                <Text> </Text>
              )}
              <AuthorName>{post.frontmatter.name_author}</AuthorName>
              <AuthorDescription>
                {post.frontmatter.author_description}
              </AuthorDescription>
            </AuthorInfo>
          </Media>
        ) : (
          ""
        )}
        <Pagination>
          <DivPrev>
            {prevArticle && (
              <LinkPrev to={prevArticle.url}>
                <TextPrev>← Anterior</TextPrev>
                <Span>{prevArticle.title}</Span>
              </LinkPrev>
            )}
          </DivPrev>
          <DivNext>
            {nextArticle && (
              <LinkNext to={nextArticle.url}>
                <TextNext>Próximo →</TextNext>
                <Span>{nextArticle.title}</Span>
              </LinkNext>
            )}
          </DivNext>
        </Pagination>
      </ContentContainer>
      <Footer>
        <FooterWrapper>
          <Content>
            <Icons>
              <Link>
                <FooterLink
                  href="https://www.facebook.com/abbiamolog"
                  target="_blank">
                  <FacebookIcon />
                </FooterLink>
              </Link>
              <Link>
                <FooterLink
                  href="https://www.instagram.com/abbiamolog/"
                  target="_blank">
                  <InstagramIcon />
                </FooterLink>
              </Link>
              <Link>
                <FooterLink
                  href="https://www.linkedin.com/company/abbiamo-log/"
                  target="_blank">
                  <LinkedinIcon />
                </FooterLink>
              </Link>
            </Icons>
            <Text>©2021 Abbiamo. Todos os direitos reservados.</Text>
          </Content>
        </FooterWrapper>
      </Footer>
    </Layout>
  );
};

export default Article;

export const pageQuery = graphql`
  query Article {
    allMarkdownRemark(
      sort: { fields: frontmatter___publish_date___start, order: DESC }
      filter: { frontmatter: { status: { name: { eq: "publicado" } } } }
    ) {
      edges {
        next {
          frontmatter {
            title
            url
          }
        }
        previous {
          frontmatter {
            title
            url
          }
        }
      }
      nodes {
        timeToRead
        frontmatter {
          cover_image_credits
          cover_image {
            external {
              url
            }
          }
          desc
          tags {
            name
          }
          title
        }
      }
    }
  }
`;
