import { Link } from "gatsby";
import styled, { css } from "styled-components";
import { BiTime, BiCalendarAlt } from "react-icons/bi";

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  text-rendering: geometricPrecision !important;
  -webkit-font-smoothing: antialiased !important;
  -webkit-text-size-adjust: 100%;
  max-width: 900px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 3rem;
  @media screen and (min-width: 768px) {
    padding: 3rem;
  }
`;

export const AllBlogLink = styled.span`
  color: #6ed796;
  text-transform: uppercase;
  font-size: 0.976rem;
  letter-spacing: 0.05rem;
  margin-bottom: 1rem;
  text-align: center;
`;

export const Avatar = styled.img`
  border-radius: 100%;
  width: 20px;
  height: 20px;
  margin-left: 0.23rem;
`;

export const Dot = styled.span`
  font-size: 0.7rem;
  color: #b2b2b2;
  border-radius: 50%;
  margin-right: 0.4rem;
  margin-left: 0.4rem;
`;

export const ContentTitle = styled.h1`
  font-size: 1.8rem;
  text-align: center;
  text-rendering: geometricPrecision !important;
  -webkit-font-smoothing: antialiased !important;
  -webkit-text-size-adjust: 100%;
  min-width: 100%;
  @media screen and (min-width: 768px) {
    font-size: 2.6rem;
  }
`;

export const CoverImgWrapper = styled.div`
  max-width: 350px;
  padding-bottom: 1rem;
  padding-top: 3rem;
  margin: 0 auto;
  @media screen and (min-width: 768px) {
    max-width: 1000px;
  }
`;

export const TimeIcon = styled(BiTime)`
  vertical-align: middle;
  margin-bottom: 0.1rem;
`;

export const CoverImage = styled.img`
  align-content: center;
  width: 100%;
`;

export const ContentDesc = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  justify-content: center;
  align-content: center;
  text-rendering: geometricPrecision !important;
  -webkit-font-smoothing: antialiased !important;
  -webkit-text-size-adjust: 100%;
  color: gray;
`;

export const ContentProperties = styled.span`
  font-size: 0.876rem;
  line-height: 1.5;
  justify-content: center;
  align-content: center;
  color: gray;
  padding-top: 0.5rem;
  text-align: center;
  vertical-align: middle;
  gap: 0.4rem;
`;

export const Item = styled.span`
  font-size: 1rem;
  margin-top: 10px;
`;

export const Calendar = styled(BiCalendarAlt)`
  vertical-align: middle;
  margin-bottom: 0.1rem;
  margin-right: 0.1rem;
`;

export const ContentText = styled.div`
  * {
    font-family: "Produkt";
    font-size: 1.1rem;
    line-height: 1.5;
    justify-content: center;
    align-content: center;
    text-rendering: geometricPrecision;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    padding-top: 2rem;
    max-width: 900px;
    margin: 0 auto;

    a {
      color: #6ed796;
      font-weight: bold;
    }
  }
`;

export const Pagination = styled.nav`
  display: flex;
  gap: 3rem;
  margin-top: 5rem;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0.7rem;
  max-width: 900px;
  margin: 0 auto;
  @media screen and (min-width: 768px) {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    -webkit-box-pack: justify;
  }
`;

export const LinkPost = css`
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.25;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  margin-top: 5rem;
`;

export const LinkPrev = styled(Link)`
  ${LinkPost}
  text-align: left;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  padding-top: 2rem;
`;

export const LinkNext = styled(Link)`
  ${LinkPost}
  text-align: right;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  padding-top: 2rem;
`;

export const LinkDiv = css`
  @media screen and (min-width: 768px) {
    width: 50%;
  }
`;

export const DivPrev = styled.div`
  ${LinkDiv}
`;
export const DivNext = styled.div`
  ${LinkDiv}
  text-align: right;
`;

export const TextPrev = styled.p`
  color: #78757a;
  font-size: 0.875rem;
  font-weight: normal;
  margin-bottom: 0.5rem;
  margin-top: 2rem;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  text-rendering: optimizelegibility;
  font-weight: normal;
`;

export const TextNext = styled.p`
  color: #78757a;
  font-size: 0.875rem;
  font-weight: normal;
  margin-bottom: 0.5rem;
  margin-top: 2rem;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  -webkit-text-size-adjust: 100%;
`;

export const Span = styled.span`
  display: inline-flex;
  color: #000000;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  -webkit-text-size-adjust: 100%;
`;

export const Divider = styled.span`
  border-bottom: 1px solid #e0e0e0;
  margin-top: 4rem;
`;

export const Media = styled.div`
  padding: 10px;
  margin-top: 10px;
  @media screen and (min-width: 767px) {
    border-radius: 3px;
    margin-bottom: 50px;
    padding: 50px;
    padding-left: 0;
    overflow: hidden;
    zoom: 1;
  }
`;
export const MediaLeft = styled.div`
  @media screen and (max-width: 767px) {
    display: block;
    width: 100%;
    padding: 0;
  }

  display: table-cell;
  vertical-align: top;
  padding-right: 10px;
  vertical-align: middle;
`;

export const AuhtorImg = styled.img`
  border-radius: 100%;
  height: 100px;
  margin: 0 auto;
  display: block;
  margin-bottom: 15px;

  @media screen and (min-width: 767px) {
    margin-right: 10px;
    display: table-cell;
    height: 110px;
  }
`;

export const AuthorInfo = styled.div`
  display: block;
  width: 100%;
  text-align: center;

  @media screen and (min-width: 767px) {
    vertical-align: middle;
    display: table-cell;
    padding-right: 10px;
    text-align: left;
  }
`;

export const Text = styled.h6`
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  color: #b3b3b3;

  @media screen and (min-width: 767px) {
    text-align: left;
  }
`;

export const AuthorName = styled.h5`
  font-size: 23px;
  line-height: 28px;
  color: #000;
`;

export const AuthorDescription = styled.p`
  font-size: 13px;
  line-height: 22px;
  font-family: "Produkt";
  margin-top: 0.5rem;
`;
